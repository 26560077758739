import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
export const constantRoutes = [
	{
		path: '/login',
		name:'login',
		component: () => import('@/views/login/index'),
		hidden: true
	},
	{
		path: '/paySuccess',
		name:'paySuccess',
		component: () => import('@/views/paySuccess'),
		hidden: true
	},
	{
		path: '/404',
		name:'',
		component: () => import('@/views/404'),
		hidden: true
	},
	{
		path: '/',
		component: Layout,
		redirect: '/home',
		children: [{
				path: 'home',
				name: 'home',
				component: () => import('@/views/home/index'),
				meta: {
					title: '首页'
				}
			}, {
				path: 'contract-review',
				name: 'contract-review',
				component: () => import('@/views/contract-review/index'),
				meta: {
					title: '合同审查'
				}
			}, {
				path: 'makeContract_index',
				name: 'makeContract_index',
				component: () => import('@/views/contract-review/makeContract_index'),
				meta: {
					title: '合同定制'
				}
			}, {
				path: 'legal-risk',
				name: 'legal-risk',
				component: () => import('@/views/legal-risk/index'),
				meta: {
					title: '法律风险体检'
				}
			}, {
				path: 'history-physical-record',
				name: 'history-physical-record',
				component: () => import('@/views/history-physical-record/index'),
				meta: {
					title: '历史体检记录'
				},
			}, {
				path: 'history-physical-result',
				name: 'history-physical-result',
				component: () => import('@/views/history-physical-result/index'),
				meta: {
					title: '历史体检记录'
				},
			}, {
				path: 'dispute',
				name: 'dispute',
				component: () => import('@/views/dispute/index'),
				meta: {
					title: '纠纷处理'
				}
			}, {
				path: 'clerical-service',
				name: 'clerical-service',
				component: () => import('@/views/clerical-service/index'),
				meta: {
					title: '文书服务'
				}
			}, {
				path: 'clerical-service2',
				name: 'clerical-service2',
				component: () => import('@/views/clerical-service2/index'),
				meta: {
					title: '诉讼辅助'
				}
			}, {
				path: 'clerical-service3',
				name: 'clerical-service3',
				component: () => import('@/views/clerical-service3/index'),
				meta: {
					title: '案件委托'
				}
			},{
				path: 'serviceQuality',
				name: 'serviceQuality',
				component: () => import('@/views/clerical-service3/serviceQuality'),
				meta: {
					title: '服务质量评价'
				}
			}, {
				path: 'clerical-service-details',
				name: 'clerical-service-details',
				component: () => import('@/views/clerical-service-details/index'),
				meta: {
					title: '服务详情'
				}
			}, {
				path: 'clerical-service-details2',
				name: 'clerical-service-details2',
				component: () => import('@/views/clerical-service-details2/index'),
				meta: {
					title: '案件委托服务'
				}
			}, {
				path: 'clerical-service-pay',
				name: 'clerical-service-pay',
				component: () => import('@/views/clerical-service-pay/index'),
				meta: {
					title: '支付'
				}
			}, {
				path: 'clerical-service-pay2',
				name: 'clerical-service-pay2',
				component: () => import('@/views/clerical-service-pay2/index'),
				meta: {
					title: '支付'
				}
			}, {
				path: 'pay-success',
				name: 'pay-success',
				component: () => import('@/views/pay-success/index'),
				meta: {
					title: '支付成功'
				}
			}, {
				path: 'pay-error',
				name: 'pay-error',
				component: () => import('@/views/pay-error/index'),
				meta: {
					title: '支付失败'
				}
			}, {
				path: 'post-prosecution',
				name: 'post-prosecution',
				component: () => import('@/views/post-prosecution/index'),
				meta: {
					title: '代写起诉状',
					keepAlive: true
				}
			}, {
				path: 'post-prosecution2',
				name: 'post-prosecution2',
				component: () => import('@/views/post-prosecution2/index'),
				meta: {
					title: '诉讼辅助服务'
				}
			}, {
				path: 'post-prosecution3',
				name: 'post-prosecution3',
				component: () => import('@/views/post-prosecution3/index'),
				meta: {
					title: '案件委托服务'
				}
			}, {
				path: 'case-progress',
				name: 'case-progress',
				component: () => import('@/views/case-progress/index'),
				meta: {
					title: '案件进度'
				}
			}, {
				path: 'contract-manage',
				name: 'contract-manage',
				component: () => import('@/views/contract-manage/index'),
				meta: {
					title: '合同管理'
				}
			}, {
				path: 'contract-calendar',
				name: 'contract-calendar',
				component: () => import('@/views/contract-calendar/index'),
				meta: {
					title: '合同履约日历'
				}
			}, {
				path: 'legal-advice',
				name: 'legal-advice',
				component: () => import('@/views/legal-advice/index'),
				meta: {
					title: '法律咨询'
				}
			}, {
				path: 'legal-advice-details',
				name: 'legal-advice-details',
				component: () => import('@/views/legal-advice-details/index'),
				meta: {
					title: '咨询详情'
				}
			}, {
				path: 'my-consult',
				name: 'my-consult',
				component: () => import('@/views/my-consult/index'),
				meta: {
					title: '我的咨询'
				}
			}, {
				path: 'consult-apply',
				name: 'consult-apply',
				component: () => import('@/views/consult-apply/index'),
				meta: {
					title: '法律咨询'
				}

			}, {
				path: 'corporate-manage',
				name: 'corporate-manage',
				component: () => import('@/views/corporate-manage/index'),
				meta: {
					title: '体检'
				}
			}, {
				path: 'corporate-manage-details',
				name: 'corporate-manage-details',
				component: () => import('@/views/corporate-manage-details/index'),
				meta: {
					title: '体检详细'
				}
			}, {
				path: 'debt-collection',
				name: 'debt-collection',
				component: () => import('@/views/debt-collection/index'),
				meta: {
					title: '律师催告服务'
				}
			}, {
				path: 'debt-collection-apply',
				name: 'debt-collection-apply',
				component: () => import('@/views/debt-collection-apply/index'),
				meta: {
					title: '申请律师发函催债'
				}
			}, {
				path: 'debt-collection-payInfo',
				name: 'debt-collection-payInfo',
				component: () => import('@/views/debt-collection-apply/payInfo'),
				meta: {
					title: '债权付款'
				}
			}, {
				path: 'debt-collection-details',
				name: 'debt-collection-details',
				component: () => import('@/views/debt-collection-details/index'),
				meta: {
					title: '律师催告服务'
				}
			}, {
				path: 'template-dwon',
				name: 'template-dwon',
				component: () => import('@/views/template-dwon/index'),
				meta: {
					title: '模板下载'
				}

			}, {
				path: 'member-renewal',
				component: () => import('@/views/member-renewal/index'),
				meta: {
					title: '会员续费'
				}
			},
			{
				path: '/feedback',
				component: () => import('@/views/feedback/index'),
				meta: {
					title: '意见反馈'
				}
			},
			{
				path: '/message',
				component: () => import('@/views/message/index'),
				meta: {
					title: '留言'
				}
			}
		]
	}
]
// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
let routerReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})