import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({
	showSpinner: false
}) // NProgress Configuration

const whiteList = ['/login','/paySuccess','/404'] 

router.beforeEach(async (to, from, next) => {
	// 启动进度条
	NProgress.start()
	// 设置页面标题
	document.title = getPageTitle(to.meta.title)

	// 确定用户是否已登录
	const hasToken = getToken()
	if (hasToken) {
		if (to.path === '/login') {
			// 如果已登录，则跳转到主页
			next({
				path: '/'
			})
			NProgress.done()
		} else {
			const hasGetUserInfo = store.getters.name
			if (hasGetUserInfo) {
				next()
			} else {
				try {
					// TODO 获取个人信息
					// await store.dispatch('user/getInfo')
					next()
				} catch (error) {
					//删除 token 并转到登录页面重新登录
					await store.dispatch('user/resetToken')
					Message.error(error || 'Has Error')
					next(`/login?redirect=${to.path}`)
					NProgress.done()
				}
			}
		}
	} else {
		// 没有token
		if (whiteList.indexOf(to.path) !== -1) {
		  // 在免登录白名单，直接进入
		  next()
		} else {
		  next(`/login?redirect=${encodeURIComponent(to.fullPath)}`) // 否则全部重定向到登录页
		  NProgress.done()
		}
	}
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
