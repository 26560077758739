<template>
	<div>
		<div class="bottom-container">
			<div class="flex-box aboutdiv">
				<el-button slot="reference" class="bottom-text" type="text" @click="chooseType(0)">关于我们</el-button>
				<el-button slot="reference" class="bottom-text" type="text" @click="chooseType(1)">联系我们</el-button>
				<el-button slot="reference" class="bottom-text" type="text"
					@click="showQuestion=true;showAbout=false;showUs=false">常见问题</el-button>
			</div>
			<div class="flex-center linkdiv">
				<el-link href="https://beian.miit.gov.cn/#/Integrated/index" type="info" class="bottom-text"
					:underline="false">
					ICP备案/许可证号：黔ICP备2021001648号-2</el-link>

				<el-link href="https://www.beian.gov.cn/portal/recordQuery?token=738450e1-4de7-4d50-8f33-5883db4ecd7c"
					type="info" class="bottom-text" :underline="false">
					<div class="flex-box">
						<img src="@/assets/1.png" style="width: 15px;height: 15px;" />
						<span class="font14 ml-5" style="color: #a6a9ad;">贵公网安备 52010202003422号</span>
					</div>
				</el-link>
			</div>
			<div class="">
				<p class="bottom-text">运营维护：贵州企事通科技有限公司</p>
			</div>
		</div>


		<!-- <el-dialog title="常见问题" :visible.sync="showQuestion" width="30%">
			<div class="columnScorll" style="max-height: 700px;overflow-y: auto;">
				<el-collapse accordion v-model="activeNames" @change="handleChange">
					<el-collapse-item :title="item.title" :name="item.id" v-for="(item,index) in questionList"
						:key="index">
						<div class="fontColor-666 font16" v-html="quertionInfo"></div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-dialog>

		<el-dialog title="关于我们" :visible.sync="showAbout" width="30%">
			<div class="ql-editor" style="max-height: 700px;" v-html="msg">
			</div>
		</el-dialog>
		<el-dialog title="联系我们" :visible.sync="showUs" width="30%">
			<div class="ql-editor" style="max-height: 700px;" v-html="msg">
			</div>
		</el-dialog> -->
		
		<el-dialog :show-close="false" title=" " :visible.sync="showQuestion" width="40%" custom-class="detailDialog">
			<div class=" mb-0">
				<div class="message-title">常见问题</div>
			</div>
			<div class="p-20 boxSizing">
				<div class="columnScorll" style="max-height: 700px;overflow-y: auto;">
				<el-collapse accordion v-model="activeNames" >
					<el-collapse-item :title="item.title" :name="item.id" v-for="(item,index) in questionList"
						:key="index">
						<div class="fontColor-666 font16" v-html="item.content"></div>
					</el-collapse-item>
				</el-collapse>
				<div class="flex-center mt-20">
					<pagination :total="total" @change="getHelpListFun"></pagination>
				</div>
			</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showQuestion=false">关 闭</el-button>
			</span>
		</el-dialog>
		
		<el-dialog :show-close="false" title=" " :visible.sync="showAbout" width="40%" custom-class="detailDialog">
			<div class=" mb-0">
				<div class="message-title">关于我们</div>
			</div>
			<div class="p-20 boxSizing mt-20">
				<div class="ql-editor" style="max-height: 700px;" v-html="msg">
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showAbout=false">关 闭</el-button>
			</span>
		</el-dialog>
		
		<el-dialog :show-close="false" title=" " :visible.sync="showUs" width="40%" custom-class="detailDialog">
			<div class=" mb-0">
				<div class="message-title">联系我们</div>
			</div>
			<div class="p-20 boxSizing mt-20">
				<div class="ql-editor" style="max-height: 700px;" v-html="msg">
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showUs=false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>

</template>

<script>
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	import {
		getInfo,
		getHelpList,
		getHelpInfo
	} from "@/api/index.js"
	export default {
		data() {
			return {
				msg: "",
				showQuestion: false,
				activeNames: null,
				questionList: [], //问题列表
				quertionInfo: "",
				showAbout: false,
				showUs: false,
				total:0,
				pageNum:1
			}
		},
		mounted() {
			this.getHelpListFun();
		},
		methods: {
			/* 关于我们，联系我们 */
			chooseType(type) {
				if (type === 0) {
					this.showAbout = true;
					this.showUs = false
					this.showQuestion = false
				} else {
					this.showAbout = false;
					this.showUs = true
					this.showQuestion = false
				}
				this.msg = ""
				getInfo({
					type: type
				}).then((res) => {
					this.msg = res.msg
				})
			},
			/* 问题列表 */
			getHelpListFun(e) {
				if(e){
					this.pageNum = e;
				}
				getHelpList({pageSize: 10,pageNum: this.pageNum}).then((res) => {
					this.questionList = res.rows;
					this.total = res.total;
				})
			},
			/* 常见问题选择 */
			handleChange(e) {
				if (e) {
					this.quertionInfo=""
					getHelpInfo(e).then((res) => {
						this.quertionInfo = res.data.content;
					})
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.bottom-container {
		width: 100%;
		height: 50px;
		background-color: #EEEEEE;
		position: fixed;
		bottom: 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		z-index: 1002;
		left: 0;

		div:first-child {}

		.aboutdiv {
			display: flex;
			justify-content: flex-start;
		}

		.linkdiv {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		div:nth-child(3n) {
			display: flex;
			justify-content: flex-end;
		}

		.bottom-text {
			margin-left: 30px;
			margin-right: 30px;
			font-size: 14px;
			color: #828282;
		}
	}

	::v-deep .el-popover {
		z-index: 9999;
	}

	::v-deep .el-collapse-item__header {
		font-size: 17px;
		font-weight: 900;
	}

	.el-popper[x-placement^=top] {
		bottom: 53px !important;
		z-index: 99999 !important;
		// height:200px !important;
	}
	
	.message-title {
		width: 100%;
		height: 80px;
		line-height: 80px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-style: normal;
		color: #FFFFFF;
		background: linear-gradient(180deg, #4074C2, #4074C2);
		position: relative;
		font-weight: 900;
		font-style: normal;
		font-size: 20px;
		color: #fff;
		padding-left: 20px;
	}
	::v-deep .el-dialog {
		border-radius: 10px;
	}
	
	::v-deep .el-dialog__header {
		display: none;
	}
	
	::v-deep .el-dialog__body{
		padding: 0 !important;
	}
</style>