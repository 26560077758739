// 基于 axios 封装的请求模块
import axios from 'axios'
import { getToken,removeToken } from '@/utils/auth'
import { blobValidate } from '@/utils/lawyer'
import errorCode from '@/utils/errorCode';
import {
	Notification,
	MessageBox,
	Message,
	Loading
} from 'element-ui'

import { saveAs } from 'file-saver'


// 定义 loading 
 let loading=null;let isLoading = false;
// loading开始 方法
 function startLoading() {  
 // element-ui loading 服务调用方式  
     loading = Loading.service({ 
         lock: true,
         text: 'loading...',
         spinner: 'el-icon-loading',  // 自定义图标
         background: 'rgba(0, 0, 0, 0.7)'
     })
 }
 
 // loading结束 方法
 function endLoading() {  
     loading.close()
 }
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例请求设置
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // 超时
  timeout: 30000 
})

// request拦截器
service.interceptors.request.use(config => {
	// 是否需要设置 token
	const isToken = (config.headers || {}).isToken === false
	// 是否需要防止数据重复提交
	const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
	if (getToken() && !isToken) {
	  config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	}
	if(config.data && config.data.creatTime){
	    config.data.creatTime = ""
	}
	
	if(config.data && config.data.loading){
		startLoading();
		isLoading=true;
	}
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?' + tansParams(config.params);
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;

	}
	if (config.method === 'post' || config.method === 'put') {
		if(config.data && config.data.createTime){
				config.data.createTime = "";
		}
	}
	
	if (config.type === 'formData') {
		config.headers['Content-Type'] = 'multipart/form-data;boundary=something'
	}
	if (config.type === 'www') {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	}
	return config
}, error => {
	if(isLoading){
		endLoading();
	}
	Promise.reject(error)
})
//get参数处理，用于将params对象参数拼接在请求地址后面
function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName];
		var part = encodeURIComponent(propName) + "=";
		if (value !== null && typeof(value) !== "undefined") {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof(value[key]) !== 'undefined') {
						let params = propName + '[' + key + ']';
						var subPart = encodeURIComponent(params) + "=";
						result += subPart + encodeURIComponent(value[key]) + "&";
					}
				}
			} else {
				result += part + encodeURIComponent(value) + "&";
			}
		}
	}
	return result
}


// 响应拦截器
service.interceptors.response.use(res => {
		if(isLoading){
			endLoading();
		}
		// 未设置状态码则默认成功状态
		const code = res.data.code || 200;
		// 获取错误信息
		const msg = errorCode[code] || res.data.msg || errorCode['default']
		// 二进制数据则直接返回
		if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
			return res.data
		}
		if (code === 401) {
			MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				removeToken();
				location.href = '/login';
			}).catch(() => {});
			return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
		} else if (code === 500) {
			// Message({
			// 	message: msg,
			// 	type: 'error'
			// })
			// return Promise.reject(new Error(msg))
			 Message({ message: msg, type: 'error' })
			 return Promise.reject(new Error(msg))
		} else if (code !== 200) {
			Notification.error({
				title: msg
			})
			return Promise.reject('error')
		} else {
			return res.data
		}
	},
	error => {
		if(isLoading){
			endLoading();
		}
		let {
			message
		} = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		Message({
			message: message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)


// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = ElLoading.service({ text: "正在下载数据，请稍候", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
	  Message({ message:errMsg, type: 'error' })
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
	Message({ message: '下载文件出现错误，请联系管理员！', type: 'error' })
    downloadLoadingInstance.close();
  })
}

export default service